export const typeorm = {};

export const PrimaryGeneratedColumn = function (p1, p2, p3) { return (p, k) => { } };
export const Column = function (p1, p2, p3) { return (p, k) => { } };
export const Check = function (p1, p2, p3) { return (p, k) => { } };
export const Embedded = function (p1, p2, p3) { return (p, k) => { } };
export const EntityListener = function (p1, p2, p3) { return (p, k) => { } };
export const ForeignKey = function (p1, p2, p3) { return (p, k) => { } };
export const Index = function (p1, p2, p3) { return (p, k) => { } };
export const RelationCount = function (p1, p2, p3) { return (p, k) => { } };
export const RelationId = function (p1, p2, p3) { return (p, k) => { } };
export const Relation = function (p1, p2, p3) { return (p, k) => { } };
export const Unique = function (p1, p2, p3) { return (p, k) => { } };
export const OneToMany = function (p1, p2, p3) { return (p, k) => { } };
export const Entity = function (p1, p2, p3) { return (p, k) => { } };
export const ManyToMany = function (p1, p2, p3) { return (p, k) => { } };
export const JoinTable = function (p1, p2, p3) { return (p, k) => { } };
export const PrimaryColumn = function (p1, p2, p3) { return (p, k) => { } };
export const ManyToOne = function (p1, p2, p3) { return (p, k) => { } };
export const AfterLoad = function (p1, p2, p3) { return (p, k) => { } };
export const AfterInsert = function (p1, p2, p3) { return (p, k) => { } };
export const AfterUpdate = function (p1, p2, p3) { return (p, k) => { } };
export const JoinColumn = function (p1, p2, p3) { return (p, k) => { } };
export const BeforeInsert = function (p1, p2, p3) { return (p, k) => { } };
export const BeforeUpdate = function (p1, p2, p3) { return (p, k) => { } };
export const OneToOne = function (p1, p2, p3) { return (p, k) => { } };
export const DeepPartial = function (p1, p2, p3) { return (p, k) => { } };
export const ChildEntity = function (p1, p2, p3) { return (p, k) => { } };
export const TableInheritance = function (p1, p2, p3) { return (p, k) => { } };
export const ViewColumn = function (p1, p2, p3) { return (p, k) => { } };
export const ViewEntity = function (p1, p2, p3) { return (p, k) => { } };
export const CreateDateColumn = function (p1, p2, p3) { return (p, k) => { } };